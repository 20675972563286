import React from "react";

function RecordCopy(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.818"
      height="16"
      viewBox="0 0 13.818 16"
      {...props}
    >
      <path
        fill="#636d82"
        d="M13.182 1.5H4.455A1.459 1.459 0 003 2.955v10.181h1.455V2.955h8.727zm2.182 2.909h-8a1.459 1.459 0 00-1.455 1.455v10.181A1.459 1.459 0 007.364 17.5h8a1.459 1.459 0 001.455-1.455V5.864a1.459 1.459 0 00-1.455-1.455zm0 11.636h-8V5.864h8z"
        data-name="Icon material-content-copy"
        transform="translate(-3 -1.5)"
      ></path>
    </svg>
  );
}

export default RecordCopy;