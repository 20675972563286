import * as React from "react";
import { SVGProps } from "react";

const SvgBellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.091}
    height={20.709}
    {...props}
  >
    <g
      data-name="Group 3603"
      fill="none"
      stroke="#6f7687"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
    >
      <path
        data-name="Path 763"
        d="M16.388 17.212H.697l1.491-5.88V6.977a5.1 5.1 0 0 1 5.286-4.892h2.135a5.1 5.1 0 0 1 5.287 4.892v4.355Z"
      />
      <path data-name="Line 662" d="M7.246 20.009h3.274" />
      <path data-name="Line 663" d="M8.545 1.848V.7" />
    </g>
  </svg>
);

export default SvgBellIcon;
