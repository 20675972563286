import React from "react";

function PartyPopper(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.616"
      height="27.616"
      viewBox="0 0 27.616 27.616"
      {...props}
    >
      <g transform="translate(-1.004 .003)">
        <path
          fill="#fdc70e"
          d="M23.155 26.788l-20.91 6.987a.942.942 0 01-1.193-1.193L8.04 11.673z"
          data-name="Path 3232"
          transform="translate(0 -6.21)"
        ></path>
        <path
          fill="#d39518"
          d="M31.151 26.785s-3.08-.7-8.746-6.369c-5.516-5.511-6.33-8.585-6.369-8.745v-.006s3.085.7 8.753 6.366 6.362 8.754 6.362 8.754z"
          data-name="Path 3233"
          transform="translate(-7.996 -6.206)"
        ></path>
        <path
          fill="#2167d8"
          d="M11.332 46.658l-3.1 1.034c-.651-.58-1.343-1.231-2.083-1.971S4.76 44.289 4.18 43.639l1.034-3.1C6 41.49 6.96 42.553 8.14 43.732s2.242 2.139 3.192 2.926z"
          data-name="Path 3234"
          transform="translate(-1.689 -21.565)"
        ></path>
        <path
          fill="#d3374e"
          d="M22.884 34.255l-3.328 1.109a38.66 38.66 0 01-5.116-4.4 38.66 38.66 0 01-4.4-5.116l1.109-3.328a34.825 34.825 0 005.28 6.455 34.825 34.825 0 006.455 5.28z"
          data-name="Path 3235"
          transform="translate(-4.807 -11.98)"
        ></path>
        <path
          fill="#2167d8"
          d="M25 10.861a.468.468 0 01-.331-.8 5.711 5.711 0 001.1-6.869.468.468 0 11.8-.479 6.669 6.669 0 01-1.245 8.013.465.465 0 01-.331.135z"
          data-name="Path 3236"
          transform="translate(-12.516 -1.311)"
        ></path>
        <path
          fill="#23af6f"
          d="M22.438 8.412a.468.468 0 01-.331-.137l-.784-.783a.468.468 0 11.662-.662l.783.783a.468.468 0 01-.331.8z"
          data-name="Path 3237"
          transform="translate(-10.735 -3.561)"
        ></path>
        <path
          fill="#d3374e"
          d="M35.041 6.739a.468.468 0 01-.468-.468v-.783a.468.468 0 11.936 0v.783a.468.468 0 01-.468.468z"
          data-name="Path 3238"
          transform="translate(-17.856 -2.672)"
        ></path>
        <path
          fill="#23af6f"
          d="M35.825 14.323h-.784a.468.468 0 010-.936h.784a.468.468 0 010 .936z"
          data-name="Path 3239"
          transform="translate(-17.856 -7.122)"
        ></path>
        <path
          fill="#2167d8"
          d="M40.061 32.218a.468.468 0 01-.331-.8 6.669 6.669 0 018.013-1.245.468.468 0 01-.481.8 5.648 5.648 0 00-6.87 1.1.468.468 0 01-.331.145z"
          data-name="Path 3240"
          transform="translate(-20.526 -15.618)"
        ></path>
        <path
          fill="#23af6f"
          d="M50.885 36.859a.468.468 0 01-.331-.137l-.784-.784a.468.468 0 11.662-.662l.784.783a.468.468 0 01-.331.8z"
          data-name="Path 3241"
          transform="translate(-25.867 -18.693)"
        ></path>
        <path
          fill="#d3374e"
          d="M52.558 24.363h-.783a.468.468 0 110-.936h.783a.468.468 0 110 .936z"
          data-name="Path 3242"
          transform="translate(-26.757 -12.463)"
        ></path>
        <path
          fill="#d3374e"
          d="M45.081 23.473a.468.468 0 01-.468-.473v-.784a.468.468 0 11.936 0V23a.468.468 0 01-.468.473z"
          data-name="Path 3243"
          transform="translate(-23.197 -11.572)"
        ></path>
        <path
          fill="#fdc70e"
          d="M36.715 13.882a.468.468 0 01-.331-.8 1.9 1.9 0 011.505-.528h.01a.928.928 0 001.086-1.1 1.844 1.844 0 012.034-2.035.936.936 0 001.1-1.1 1.9 1.9 0 01.529-1.506.468.468 0 11.66.664 1 1 0 00-.255.843 1.845 1.845 0 01-2.036 2.036.933.933 0 00-1.1 1.1 1.9 1.9 0 01-.528 1.505 1.921 1.921 0 01-1.505.528h-.011a.98.98 0 00-.832.254.468.468 0 01-.331.138z"
          data-name="Path 3244"
          transform="translate(-18.747 -3.549)"
        ></path>
        <g
          fill="#d3374e"
          data-name="Group 12065"
          transform="translate(19.066 -.003)"
        >
          <path
            d="M40.061 4.851a.468.468 0 01-.433-.646 4.34 4.34 0 00.02-3.518.468.468 0 11.826-.442 5.278 5.278 0 01.021 4.316.468.468 0 01-.433.29z"
            data-name="Path 3245"
            transform="translate(-39.592 .003)"
          ></path>
          <path
            d="M50.1 18.869a.468.468 0 01-.178-.9 5.273 5.273 0 014.317.021.468.468 0 01-.442.826 4.338 4.338 0 00-3.518.02.471.471 0 01-.178.035z"
            data-name="Path 3246"
            transform="translate(-44.933 -9.316)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default PartyPopper;
