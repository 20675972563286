import React from "react";

function AdminSetting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g fill="#020405" data-name="setting (2)" transform="translate(-1 -1)">
        <path
          d="M20.85 9.067h-.094a.685.685 0 01-.634-.44.678.678 0 01.147-.741l.067-.067a2.155 2.155 0 000-3.041l-1.108-1.107a2.152 2.152 0 00-3.04 0l-.067.067a.7.7 0 01-1.187-.493V3.15A2.152 2.152 0 0012.783 1h-1.566a2.152 2.152 0 00-2.15 2.15v.095a.7.7 0 01-1.184.49l-.063-.067a2.152 2.152 0 00-3.04 0L3.668 4.775a2.155 2.155 0 000 3.041l.067.067a.686.686 0 01.136.759.678.678 0 01-.626.425H3.15A2.152 2.152 0 001 11.217v1.566a2.152 2.152 0 002.15 2.15h.094a.685.685 0 01.634.44.678.678 0 01-.147.741l-.067.067a2.155 2.155 0 000 3.041l1.107 1.107a2.152 2.152 0 003.041 0l.067-.067a.686.686 0 01.759-.136.676.676 0 01.425.626v.095A2.152 2.152 0 0011.217 23h1.566a2.152 2.152 0 002.15-2.15v-.095a.7.7 0 011.184-.49l.067.067a2.152 2.152 0 003.04 0l1.108-1.107a2.155 2.155 0 000-3.041l-.067-.067a.686.686 0 01-.136-.759.678.678 0 01.626-.425h.094A2.152 2.152 0 0023 12.783v-1.566a2.152 2.152 0 00-2.15-2.15zm.683 3.717a.684.684 0 01-.683.683h-.094a2.168 2.168 0 00-1.528 3.687l.066.067a.685.685 0 010 .967l-1.108 1.107a.683.683 0 01-.967 0l-.066-.067a2.169 2.169 0 00-3.687 1.528v.095a.684.684 0 01-.683.683h-1.566a.684.684 0 01-.683-.683v-.095a2.164 2.164 0 00-3.687-1.527l-.067.071a.685.685 0 01-.967 0l-1.107-1.111a.685.685 0 010-.967l.067-.067a2.168 2.168 0 00-1.528-3.688H3.15a.684.684 0 01-.683-.683v-1.567a.684.684 0 01.683-.683h.094a2.168 2.168 0 001.528-3.688l-.066-.066a.684.684 0 010-.967L5.813 4.7a.683.683 0 01.967 0l.066.067a2.169 2.169 0 003.688-1.527v-.09a.684.684 0 01.683-.683h1.566a.684.684 0 01.683.683v.095a2.168 2.168 0 003.687 1.527l.067-.067a.683.683 0 01.967 0l1.107 1.108a.684.684 0 010 .967l-.067.067a2.168 2.168 0 001.528 3.687h.094a.684.684 0 01.683.683z"
          data-name="Path 3294"
        ></path>
        <path
          d="M14.133 9a5.133 5.133 0 105.133 5.133A5.133 5.133 0 0014.133 9zm0 8.8a3.667 3.667 0 113.667-3.667 3.667 3.667 0 01-3.667 3.667z"
          data-name="Path 3295"
          transform="translate(-2.133 -2.133)"
        ></path>
      </g>
    </svg>
  );
}

export default AdminSetting;
