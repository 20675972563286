import React from "react";

function ErrorCross(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.63"
      height="18.63"
      viewBox="0 0 18.63 18.63"
      {...props}
    >
      <g data-name="Group 12097" transform="translate(-726 -938)">
        <circle
          cx="9.315"
          cy="9.315"
          r="9.315"
          fill="#dd5269"
          data-name="Ellipse 7681"
          transform="translate(726 938)"
        ></circle>
        <g data-name="cancel (1)" transform="translate(731 942.999)">
          <g data-name="Group 12096" transform="translate(0 .001)">
            <path
              fill="#fff"
              stroke="#fff"
              strokeWidth="0.6"
              d="M4.954 4.461L8.82.599a.351.351 0 10-.493-.5L4.461 3.968.595.099a.352.352 0 00-.495.5l3.868 3.862L.1 8.327a.349.349 0 00.493.493l3.868-3.866L8.327 8.82a.349.349 0 00.493-.493z"
              data-name="Path 3252"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ErrorCross;
