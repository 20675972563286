import * as React from "react";

const Time = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.255 20.255"
    {...props}
  >
    <g
      data-name="Group 4245"
      transform="translate(-1433.69 -519.5)"
      fill="none"
      stroke="#fcacac"
      strokeLinecap="round"
    >
      <rect
        data-name="Rectangle 2642"
        width={19.255}
        height={19.255}
        rx={4}
        transform="translate(1434.19 520)"
        strokeLinejoin="round"
      />
      <g data-name="Group 4246">
        <path
          data-name="Line 825"
          strokeLinejoin="round"
          d="m1443.818 529.925 3.084-3.271"
        />
        <path data-name="Path 923" d="M1443.818 530v2.6" />
      </g>
    </g>
  </svg>
);

export default Time;
