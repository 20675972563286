import React from "react";

function SubMenuDownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.085"
      height="8.228"
      viewBox="0 0 15.085 8.228"
    >
      <path
        fill="#020405"
        d="M27.542 48.228a.684.684 0 01-.485-.2L20.2 41.17a.686.686 0 01.97-.97l6.372 6.372 6.372-6.372a.686.686 0 01.97.97l-6.857 6.857a.684.684 0 01-.485.2z"
        transform="translate(-20 -40)"
      ></path>
    </svg>
  );
}

export default SubMenuDownArrow;