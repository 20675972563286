import * as React from "react";
import { SVGProps } from "react";

const Copy3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.375"
    height="24.454"
    viewBox="0 0 22.375 24.454"
    {...props}
  >
    <g
      data-name="Group 4461"
      transform="translate(-1279.65 -3801.539)"
      fill="none"
      stroke="#636d82"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
    >
      <rect
        data-name="Rectangle 232"
        width={18.412}
        height={20.491}
        rx={3.671}
        transform="translate(1280.35 3804.802)"
      />
      <path
        data-name="Path 175"
        d="M1289.48 3802.239h6.942a4.917 4.917 0 0 1 4.9 4.9v11.183"
      />
    </g>
  </svg>
);

export default Copy3;
