import * as React from "react"

const SendIcon = (props:React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 11066">
      <g data-name="Group 4458" transform="translate(-1338.563 -351.428)">
        <rect
          data-name="Rectangle 2742"
          width={48}
          height={48}
          rx={8}
          transform="translate(1338.563 351.428)"
          fill="#f4f8fb"
        />
        <g data-name="Component 146 \u2013 2">
          <g data-name="Group 4455">
            <path
              data-name="Path 783"
              d="m1370.71 370.302-10.073 5.546-10.074-5.546"
              fill="none"
              stroke="#636d82"
              strokeLinecap="round"
              strokeWidth={1.2}
            />
            <path
              data-name="Path 935"
              d="m1373.921 373.075 1.532 2.638-1.532 2.637"
              fill="none"
              stroke="#636d82"
              strokeLinecap="round"
              strokeWidth={1.2}
            />
            <path
              data-name="Path 936"
              d="m1374.762 375.682-4.888.064"
              fill="none"
              stroke="#636d82"
              strokeLinecap="round"
              strokeWidth={1.2007199999999998}
            />
            <g data-name="Group 3269">
              <path
                data-name="Rectangle 232"
                d="M1371.048 380.307a3.349 3.349 0 0 1-.38 1.559 3.085 3.085 0 0 1-2.723 1.689h-14.278a3.178 3.178 0 0 1-3.1-3.249v-10.629a3.179 3.179 0 0 1 3.1-3.249h14.278a3.179 3.179 0 0 1 3.1 3.249"
                fill="none"
                stroke="#636d82"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.2}
              />
            </g>
            <path
              data-name="Path 937"
              d="m1371.042 369.549.018 2.263"
              fill="none"
              stroke="#636d82"
              strokeLinecap="round"
              strokeWidth={1.199988}
            />
            <path
              data-name="Path 938"
              d="M1371.048 379.174v1.284"
              fill="none"
              stroke="#636d82"
              strokeLinecap="round"
              strokeWidth={1.19976}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SendIcon
