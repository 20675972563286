import React from "react";

function UploadOrg(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.912"
      height="21.15"
      viewBox="0 0 21.912 21.15"
    >
      <path
        fill="#7ab8d9"
        fillRule="evenodd"
        d="M6.934 7.714a1.22 1.22 0 101.22 1.22 1.22 1.22 0 00-1.22-1.22zM4.25 8.934a2.684 2.684 0 112.684 2.684A2.684 2.684 0 014.25 8.934z"
        data-name="Path 3290"
        transform="translate(-.346 -.228)"
      ></path>
      <path
        fill="#7ab8d9"
        fillRule="evenodd"
        d="M10.018 15.5a32.358 32.358 0 00-3.837 5.625.732.732 0 11-1.281-.718 33.774 33.774 0 014.021-5.882 10.042 10.042 0 014.033-2.97 5.439 5.439 0 014.215.251 11.945 11.945 0 014.006 3.413.732.732 0 01-1.135.925 10.534 10.534 0 00-3.489-3.011 3.979 3.979 0 00-3.117-.194 8.626 8.626 0 00-3.416 2.561z"
        data-name="Path 3291"
        transform="translate(-.359 -.348)"
      ></path>
      <path
        fill="#7ab8d9"
        fillRule="evenodd"
        d="M1.608 3.608A4.636 4.636 0 014.886 2.25h7.105a.732.732 0 110 1.464H4.886a3.172 3.172 0 00-3.172 3.172v9.76a3.172 3.172 0 003.172 3.172H16.6a3.172 3.172 0 003.172-3.172v-4.88a.732.732 0 011.464 0v4.88a4.636 4.636 0 01-4.636 4.636H4.886A4.636 4.636 0 01.25 16.646v-9.76a4.636 4.636 0 011.358-3.278z"
        data-name="Path 3292"
        transform="translate(-.25 -.132)"
      ></path>
      <path
        fill="#7ab8d9"
        fillRule="evenodd"
        d="M18.732.08a.732.732 0 01.732.732V8.62a.732.732 0 01-1.464 0V.812a.732.732 0 01.732-.732z"
        data-name="Path 3293"
        transform="translate(-.676 -.08)"
      ></path>
      <path
        fill="#7ab8d9"
        fillRule="evenodd"
        d="M18.138.294a.732.732 0 011.035 0L22.3 3.418a.732.732 0 11-1.035 1.035l-2.609-2.606-2.606 2.606a.732.732 0 11-1.035-1.035z"
        data-name="Path 3294"
        transform="translate(-.599 -.08)"
      ></path>
    </svg>
  );
}

export default UploadOrg;
