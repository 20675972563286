import React from "react";

function Microphone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.834"
      height="15"
      viewBox="0 0 11.834 15"
    >
      <g data-name="Group 12814" transform="translate(-237.459 -1532.154)">
        <g data-name="Group 11739" transform="translate(-658.957 1058.654)">
          <g
            fill="none"
            stroke="#a8acb6"
            data-name="Group 11738"
            transform="translate(.273)"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1"
              d="M0 0L0 1.5"
              data-name="Line 1000"
              transform="translate(902.06 486.5)"
            ></path>
            <g
              strokeWidth="1.1"
              data-name="Rectangle 3193"
              transform="translate(898 473.5)"
            >
              <rect width="8.175" height="11" stroke="none" rx="3"></rect>
              <rect
                width="7.075"
                height="9.9"
                x="0.55"
                y="0.55"
                rx="2.45"
              ></rect>
            </g>
            <path
              strokeLinecap="round"
              strokeWidth="1.1"
              d="M907.427 482.5c0 1.91-2.4 3.458-5.367 3.458s-5.367-1.548-5.367-3.458h0"
              data-name="Path 14966"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Microphone;
