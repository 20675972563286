import React from "react";

function RecordDownload(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      data-name="Group 11604"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        fill="none"
        stroke="#636d82"
        strokeWidth="1"
        data-name="Rectangle 2578"
      >
        <rect width="16" height="16" stroke="none" rx="4"></rect>
        <rect width="15" height="15" x="0.5" y="0.5" rx="3.5"></rect>
      </g>
      <g fill="none" data-name="Group 4048" transform="translate(3.741 3.724)">
        <g data-name="Path 907">
          <path
            d="M4.259 0l4.259 3.86H0z"
            transform="rotate(180 4.259 4.343)"
          ></path>
          <path
            fill="#636d82"
            d="M4.259 1.35L2.592 2.86h3.333L4.26 1.35m0-1.35l4.259 3.86H0L4.259 0z"
            transform="rotate(180 4.259 4.343)"
          ></path>
        </g>
        <path
          stroke="#636d82"
          strokeLinecap="round"
          strokeWidth="1"
          d="M0 4.738L0 0"
          data-name="Line 816"
          transform="translate(4.259)"
        ></path>
      </g>
    </svg>
  );
}

export default RecordDownload;