import * as React from "react"
 
const DeleteIcon = (props:React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 11064" transform="translate(-791.118 -180.304)">
      <rect
        data-name="Rectangle 2739"
        width={48}
        height={48}
        rx={8}
        transform="translate(791.118 180.304)"
        fill="#f4f8fb"
      />
      <g
        data-name="Component 6 \u2013 5"
        fill="none"
        stroke="#ff9494"
        strokeLinecap="round"
        strokeWidth={1.2}
      >
        <path
          data-name="Rectangle 2398"
          d="M809.088 197.56h12.522v12.529a2 2 0 0 1-2 2h-8.522a2 2 0 0 1-2-2V197.56h0Z"
        />
        <path data-name="Line 782" d="M807.118 197.56h16.501" />
        <path data-name="Line 783" d="M813.122 195.304h4.454" />
        <path data-name="Line 784" d="M812.053 200.873v7.403" />
        <path data-name="Line 785" d="M815.349 200.873v7.403" />
        <path data-name="Line 786" d="M818.532 200.873v7.403" />
      </g>
    </g>
  </svg>
)

export default DeleteIcon
