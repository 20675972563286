import * as React from "react";

const ConvayHostSettings = (props: any) => {
  return (
    <svg
      id="con-host-settings"
      xmlns="http://www.w3.org/2000/svg"
      width={20.285}
      height={20.536}
      viewBox="0 0 20.285 20.536"
      ref={props.svgref}
      {...props}
    >
      <g data-name="Group 11103">
        <g data-name="Group 4906" fill="none" stroke="#d3ffb1" strokeWidth={3}>
          <g data-name="Group 3683" strokeLinecap="round">
            <g data-name="Group 3679">
              <path data-name="Line 765" d="M10.142 3.254V1.5" />
              <path data-name="Line 766" d="M10.142 19.037v-1.754" />
            </g>
            <g data-name="Group 3680">
              <path data-name="Line 765" d="M15.903 10.269h2.881" />
              <path data-name="Line 766" d="M1.499 10.269H4.38" />
            </g>
            <g data-name="Group 3681">
              <path data-name="Line 765" d="m14.216 14.342 2.037 2.037" />
              <path data-name="Line 766" d="m4.03 4.157 2.038 2.037" />
            </g>
            <g data-name="Group 3682">
              <path data-name="Line 765" d="M6.068 14.343 4.031 16.38" />
              <path data-name="Line 766" d="m16.253 4.158-2.037 2.037" />
            </g>
          </g>
          <g data-name="Ellipse 7449" transform="translate(3.128 3.254)">
            <circle cx={7.014} cy={7.014} r={7.014} stroke="none" />
            <circle cx={7.014} cy={7.014} r={5.514} />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ConvayHostSettings;
