import * as React from "react"

const CopyIcon = (props:React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g data-name="Group 11067" transform="translate(-651.464 -180.304)">
      <rect
        data-name="Rectangle 2587"
        width={48}
        height={48}
        rx={8}
        transform="translate(651.464 180.304)"
        fill="#f4f8fb"
      />
      <g
        data-name="Component 3 \u2013 2"
        transform="translate(668.464 195.304)"
        fill="none"
        stroke="#636d82"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      >
        <rect
          data-name="Rectangle 232"
          width={12.806}
          height={14.177}
          rx={3}
          transform="translate(0 2.866)"
        />
        <path
          data-name="Path 175"
          d="M6.318 0h5.483a3.884 3.884 0 0 1 3.872 3.873v8.832"
        />
      </g>
    </g>
  </svg>
)

export default CopyIcon
