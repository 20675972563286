import React from "react";

function MenuCollapseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.126"
      height="14.384"
      viewBox="0 0 8.126 14.384"
    >
      <path
        fill="#020405"
        stroke="#020405"
        strokeWidth="0.5"
        d="M.25 7.192a.629.629 0 00.185.442l6.315 6.315a.631.631 0 00.892-.892L1.773 7.192l5.864-5.865A.631.631 0 106.75.434L.435 6.746a.629.629 0 00-.185.446z"
      ></path>
    </svg>
  );
}

export default MenuCollapseIcon;
