import React from "react";

function DeleteRecord(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.73"
      height="17"
      viewBox="0 0 16.73 17"
      {...props}
    >
      <g
        fill="none"
        stroke="#636d82"
        strokeLinecap="round"
        strokeWidth="1"
        data-name="Group 13323"
        transform="translate(-1736.367 -417.557)"
      >
        <path
          d="M1739.157 420.678h11.1v11.378a2 2 0 01-2 2h-7.1a2 2 0 01-2-2v-11.378h0z"
          data-name="Rectangle 2398"
        ></path>
        <path
          d="M0 0L15.73 0"
          data-name="Line 782"
          transform="translate(1736.867 420.678)"
        ></path>
        <path
          d="M0 0L5.178 0"
          data-name="Line 783"
          transform="translate(1742.126 418.057)"
        ></path>
        <path
          d="M0 0L0 8.606"
          data-name="Line 784"
          transform="translate(1741.571 422.446)"
        ></path>
        <path
          d="M0 0L0 8.606"
          data-name="Line 785"
          transform="translate(1744.713 422.446)"
        ></path>
        <path
          d="M0 0L0 8.606"
          data-name="Line 786"
          transform="translate(1747.748 422.446)"
        ></path>
      </g>
    </svg>
  );
}

export default DeleteRecord;