import React from "react";

function Verified(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.638"
      height="23.638"
      viewBox="0 0 23.638 23.638"
      {...props}
    >
      <path
        fill="#365d7e"
        d="M12.819 1a11.819 11.819 0 1011.819 11.819A11.812 11.812 0 0012.819 1zm6.146 9.208l-6.447 6.715a1.073 1.073 0 01-.774.322 1.061 1.061 0 01-.677-.236l-4.3-3.492a1.073 1.073 0 111.354-1.665l3.535 2.869 5.759-6.006a1.075 1.075 0 111.547 1.493z"
        transform="translate(-1 -1)"
      ></path>
    </svg>
  );
}

export default Verified;
