import React from "react";

function SendMultipleInvitation() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path d="M89.8 14.2c0-.1 0-.1 0 0-.3-.3-.6-.5-.9-.7h-.1-.1c-1.5-.8-3.2-1-4.9-.5L12.3 37.1c-2.2.7-3.8 2.6-4 4.9-.3 2.3.8 4.5 2.7 5.8l20.2 13 2.2 21.1c.2 2.1 1.5 3.9 3.4 4.8h.1c.8.4 1.6.5 2.5.5 1.3 0 2.5-.4 3.5-1.2l13.3-9.8L71 85.9c1 .7 2.1 1 3.3 1 .8 0 1.6-.2 2.4-.5 1.9-.8 3.2-2.4 3.5-4.4l11.6-62.4c.3-1.9-.4-4-2-5.4zM43.5 65.4s-.1.1 0 0c-.1.1-.1.2-.1.2v.1c0 .1-.1.1-.1.2v.1l-6 14.6-2.1-20 40.3-32.7-32 37.5zM12.2 42.6c.1-.8.6-1.4 1.3-1.6l67.6-22.8-48.2 39-19.8-12.7c-.7-.4-1-1.1-.9-1.9zm28.4 40.3L46 69.7l6.6 4.3-12 8.9zm35.5-1.5c-.1.7-.6 1.2-1.2 1.5-.6.3-1.3.2-1.9-.2L48 66.3l39.7-46.6-11.6 61.7z"></path>
        </svg>
    );
}

export default SendMultipleInvitation;


