import React from "react";

function SvgAudioVideoSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.064"
      height="20.064"
      viewBox="0 0 20.064 20.064"
    >
      <g
        fill="none"
        stroke="#6f7687"
        strokeLinecap="round"
        data-name="Group 1483"
        transform="translate(.75 .75)"
      >
        <path
          strokeWidth="1.5"
          d="M0 2.151L0 0"
          data-name="Line 242"
          transform="translate(9.282)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 2.329L0 0"
          data-name="Line 243"
          transform="translate(9.282 16.236)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 1.521L1.521 0"
          data-name="Line 244"
          transform="translate(14.325 2.719)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 1.647L1.647 0"
          data-name="Line 245"
          transform="translate(2.719 14.199)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 0L2.151 0"
          data-name="Line 246"
          transform="translate(16.413 9.282)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 0L2.329 0"
          data-name="Line 247"
          transform="translate(0 9.282)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 0L1.521 1.521"
          data-name="Line 248"
          transform="translate(14.325 14.325)"
        ></path>
        <path
          strokeWidth="1.5"
          d="M0 0L1.647 1.647"
          data-name="Line 249"
          transform="translate(2.719 2.719)"
        ></path>
        <ellipse
          cx="6.655"
          cy="6.655"
          strokeWidth="1.2"
          data-name="Ellipse 7190"
          rx="6.655"
          ry="6.655"
          transform="translate(2.628 2.628)"
        ></ellipse>
      </g>
    </svg>
  );
}

export default SvgAudioVideoSettings;
