import React from "react";

function BluePlus(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.713"
      height="19.713"
      viewBox="0 0 19.713 19.713"
      {...props}
    >
      <g
        fill="#365d7e"
        stroke="#365d7e"
        strokeWidth="0.4"
        data-name="03 Login"
        transform="translate(.2 .2)"
      >
        <path
          d="M9.656 19.313a.943.943 0 01-.943-.943V.943a.943.943 0 111.886 0V18.37a.943.943 0 01-.943.943z"
          data-name="Path 3223"
        ></path>
        <path
          d="M18.37 10.599H.943a.943.943 0 110-1.886H18.37a.943.943 0 110 1.886z"
          data-name="Path 3224"
        ></path>
      </g>
    </svg>
  );
}

export default BluePlus;
