import React from "react";

function SettingsGreen(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      width={props.width}
      {...props}
      viewBox="0 0 20.285 20.536"
    >
      <g data-name="Group 11010" transform="translate(-714.318 -420.738)">
        <g
          fill="none"
          stroke="#21b1b0"
          strokeLinecap="round"
          strokeWidth="3"
          data-name="Group 3679"
          transform="translate(724.462 422.238)"
        >
          <path d="M0 1.754L0 0" data-name="Line 765"></path>
          <path
            d="M0 1.754L0 0"
            data-name="Line 766"
            transform="translate(0 15.783)"
          ></path>
        </g>
        <g
          fill="none"
          stroke="#21b1b0"
          strokeLinecap="round"
          strokeWidth="3"
          data-name="Group 3680"
          transform="rotate(90 151.047 582.056)"
        >
          <path d="M0 2.881L0 0" data-name="Line 765"></path>
          <path
            d="M0 2.881L0 0"
            data-name="Line 766"
            transform="translate(0 14.404)"
          ></path>
        </g>
        <g
          fill="none"
          stroke="#21b1b0"
          strokeLinecap="round"
          strokeWidth="3"
          data-name="Group 3681"
          transform="rotate(135 274.756 369.865)"
        >
          <path d="M0 2.881L0 0" data-name="Line 765"></path>
          <path
            d="M0 2.881L0 0"
            data-name="Line 766"
            transform="translate(0 14.404)"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#21b1b0"
          strokeLinecap="round"
          strokeWidth="3"
          d="M0 2.881L0 0"
          data-name="Line 766"
          transform="rotate(-135 452.688 62.582)"
        ></path>
        <g
          fill="none"
          stroke="#21b1b0"
          strokeWidth="3"
          data-name="Ellipse 7449"
          transform="translate(717.447 423.992)"
        >
          <circle cx="7.014" cy="7.014" r="7.014" stroke="none"></circle>
          <circle cx="7.014" cy="7.014" r="5.514"></circle>
        </g>
        <g data-name="Group 11011" transform="rotate(-135 449.705 69.784)">
          <path
            fill="none"
            stroke="#21b1b0"
            strokeLinecap="round"
            strokeWidth="3"
            d="M0 2.881L0 0"
            data-name="Line 765"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SettingsGreen;
