import React from "react";

function FileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.351"
      height="24.091"
      viewBox="0 0 18.351 24.091"
    >
      <g fill="#707374" data-name="document (4)" transform="translate(-61)">
        <path
          d="M78.541 5L74 .708A2.578 2.578 0 0072.226 0h-8.638A2.591 2.591 0 0061 2.588V21.5a2.591 2.591 0 002.588 2.588h13.175a2.591 2.591 0 002.588-2.588V6.88a2.6 2.6 0 00-.81-1.88zm-1.371.646h-3.513a.236.236 0 01-.235-.235V2.1zm-.408 17.033H63.588a1.178 1.178 0 01-1.176-1.179V2.588a1.178 1.178 0 011.176-1.176h8.422v4a1.649 1.649 0 001.647 1.647h4.282V21.5a1.178 1.178 0 01-1.176 1.179z"
          data-name="Path 3308"
        ></path>
        <path
          d="M139.057 200h-10.351a.706.706 0 100 1.412h10.352a.706.706 0 000-1.412z"
          data-name="Path 3309"
          transform="translate(-63.847 -190.589)"
        ></path>
        <path
          d="M139.057 280h-10.351a.706.706 0 100 1.412h10.352a.706.706 0 000-1.412z"
          data-name="Path 3310"
          transform="translate(-63.847 -266.825)"
        ></path>
        <path
          d="M132.127 360h-3.422a.706.706 0 100 1.412h3.422a.706.706 0 000-1.412z"
          data-name="Path 3311"
          transform="translate(-63.847 -343.061)"
        ></path>
      </g>
    </svg>
  );
}

export default FileIcon;
