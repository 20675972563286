import React from "react";
import styles from "./Loader.module.css";

export default function Loader({ circleStyle }: any) {
  return (
    <div className={styles.spinner}>
      <div className={styles.bounce1} style={circleStyle}></div>
      <div className={styles.bounce2} style={circleStyle}></div>
      <div className={styles.bounce3} style={circleStyle}></div>
    </div>
  );
}
