import React from "react";

function BothError() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.028"
            height="27.028"
            data-name="information (1)"
            viewBox="0 0 27.028 27.028"
        >
            <path
                fill="#ce1e3b"
                d="M13.514 0a13.514 13.514 0 1013.514 13.514A13.53 13.53 0 0013.514 0zm0 24.571a11.057 11.057 0 1111.057-11.057 11.069 11.069 0 01-11.057 11.057z"
                data-name="Path 3267"
            ></path>
            <path
                fill="#ce1e3b"
                d="M146.64 70a1.638 1.638 0 101.638 1.639A1.64 1.64 0 00146.64 70z"
                data-name="Path 3268"
                transform="translate(-133.126 -64.267)"
            ></path>
            <path
                fill="#ce1e3b"
                d="M151.229 140a1.229 1.229 0 00-1.229 1.229v7.371a1.229 1.229 0 002.457 0v-7.371a1.229 1.229 0 00-1.228-1.229z"
                data-name="Path 3269"
                transform="translate(-137.714 -128.533)"
            ></path>
        </svg>
    );
}

export default BothError;