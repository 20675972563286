import { useSelector } from "react-redux";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { useLocation } from 'react-router-dom';
import { RootState } from "../../redux/store";
import MatrixChat from "../main/matrix-chat/MatrixChat";
export default function Layout({ children, ...props }: any) {
  const location = useLocation();

  // Check if the current route is '/chat'
  const showMatrix = useSelector((state: RootState) => state.matrix.showMatrix);

  const shouldDisplayMatrixChat = useSelector(
    (state: RootState) => state.matrix.isChatVisible
  );

  // Conditionally set the class name
  const contentClassName = showMatrix ? "cnv-content-chat" : "cnv-content"; 
  return (
    <div className="cnv-wrapper cnv-theme-light">
      {props.from !== "orgDelete" ? <Sidebar/> : ""}
      <div className="cnv-content-area">
        <Header />
        <div className={contentClassName}>
          <div className="cnv-chat-wrapper">
          {shouldDisplayMatrixChat && <div style={showMatrix ? { display: "block" } : { display: "none" }}>
            <MatrixChat />
          </div>}
          </div>
          <div id="portalForModal" style={{ position: "relative" }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
