import React, { useState, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from "react";
import configDev from "../../../../config.dev.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import useJwtToken from "../../../config/auth/useJwtToken";
import { setIframeRef, setJwtToken, setMatrixUserId } from "../../../redux/slices/showMatrixChatSlice";

const MatrixChat = forwardRef((props, ref) => {
  const authUser = useJwtToken();
  const dispatch = useDispatch();
  const elementUrl = configDev["element-frontend-url"];
  const [matrixUrl, setMatrixUrl] = useState(elementUrl);
  const matrixState = useSelector((state: RootState) => state.matrix);

  const updateMatrixUrl = () => {
    let newUrl = matrixUrl;

    if (matrixState.jwtToken) {
      const jwtRegex = /\/jwt=[^\/]+/;
      if (jwtRegex.test(newUrl)) {
        newUrl = newUrl.replace(jwtRegex, `/jwt=${matrixState.jwtToken}`);
      } else {
        newUrl += `/#/jwt=${matrixState.jwtToken}`;
      }
    }

    if (matrixState.userId) {
      const userIdRegex = /\/userId=[^\/]+/;
      if (userIdRegex.test(newUrl)) {
        newUrl = newUrl.replace(userIdRegex, `/userId=${matrixState.userId}`);
      } else {
        newUrl += `/userId=${matrixState.userId}`;
      }
    }

    if (matrixState.roomId) {
      const roomIdRegex = /\/roomId=[^\/]+/;
      if (roomIdRegex.test(newUrl)) {
        newUrl = newUrl.replace(roomIdRegex, `/roomId=${matrixState.roomId}`);
      } else {
        newUrl += `/roomId=${matrixState.roomId}`;
      }
    }

    setMatrixUrl(newUrl);
  };

  useEffect(() => {
    updateMatrixUrl();
  }, [matrixState.jwtToken, matrixState.userId, matrixState.roomId]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (authUser) {
      dispatch(setJwtToken(authUser?.token as string));
      dispatch(setMatrixUserId(authUser.user["ID"]));
    }
  }, [authUser, dispatch]);

  useEffect(() => {
    if (iframeRef.current) {
      dispatch(setIframeRef(iframeRef.current));
    }
  }, [iframeRef, dispatch]);

  const shouldRenderMatrix = useMemo(
    () => matrixUrl && matrixUrl.includes(elementUrl),
    [matrixUrl, elementUrl]
  );

  useImperativeHandle(ref, () => ({
    sendMessageToIframe: (message: string) => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(message, "*");
      }
    }
  }));

  console.log('matrix url with jwt', matrixUrl);

  if (shouldRenderMatrix && matrixUrl) {
    return (
      <iframe
        ref={iframeRef}
        style={{ border: "none" }}
        width="100%"
        height="750px"
        src={matrixUrl}
        allow="camera; microphone"
      ></iframe>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h4>Unable to load chat at the moment, sorry for the inconvenience</h4>
    </div>
  );
});

export default MatrixChat;
