import React from "react";

function SuccessCheck() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104.099"
            height="104.099"
            data-name="check (5)"
            viewBox="0 0 104.099 104.099"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.808"
                    x2="0.187"
                    y1="0.115"
                    y2="0.87"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#365d7e"></stop>
                    <stop offset="1" stopColor="#d7dfe5"></stop>
                </linearGradient>
            </defs>
            <g data-name="Group 12312">
                <g data-name="Group 12311">
                    <path
                        fill="url(#linear-gradient)"
                        d="M52.049 0A52.049 52.049 0 10104.1 52.049 52.106 52.106 0 0052.049 0zm0 96.035a43.986 43.986 0 1143.986-43.986 44.037 44.037 0 01-43.986 43.986z"
                        data-name="Path 3282"
                    ></path>
                </g>
            </g>
            <g data-name="Group 12314" transform="translate(26.854 32.902)">
                <g data-name="Group 12313">
                    <path
                        fill="url(#linear-gradient)"
                        d="M181.154 162.869a4.033 4.033 0 00-5.693.274l-24.594 27.081-11.882-12.079a4.031 4.031 0 10-5.749 5.652l14.873 15.119a4.027 4.027 0 002.874 1.205.487.487 0 01.077 0 4.034 4.034 0 002.907-1.322l27.46-30.238a4.034 4.034 0 00-.273-5.692z"
                        data-name="Path 3283"
                        transform="translate(-132.079 -161.824)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default SuccessCheck;