import * as React from "react";
import { SVGProps } from "react";

const EditCalEvent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18.731 18.57"
    {...props}
  >
    <g data-name="Group 1840">
      <path
        data-name="Path 176"
        d="m17.093 7.231-10.5 10.53-5.8.009.076-5.753 10.5-10.53a1.82 1.82 0 0 1 2.528-.293l3.492 3.5a1.834 1.834 0 0 1-.296 2.537Z"
        fill="none"
        stroke="#636d82"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
      <path
        data-name="Path 177"
        d="m.827 15.703 2.23 2.23-2.163-.163Z"
        fill="#636d82"
      />
      <path
        data-name="Path 430"
        d="M12.765 8.263 9.898 5.396"
        fill="none"
        stroke="#636d82"
        strokeLinecap="round"
        strokeWidth={1.2}
      />
    </g>
  </svg>
);

export default EditCalEvent;
