import React from "react";

function EditOrg(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.361"
      height="17.04"
      viewBox="0 0 16.361 17.04"
      {...props}
    >
      <g transform="translate(-7.688 .15)">
        <g data-name="Group 12088" transform="translate(7.838)">
          <g
            fill="#365d7e"
            stroke="#365d7e"
            strokeWidth="0.3"
            data-name="Group 12087"
          >
            <path
              d="M1.245 13.392h.09l4.366-.95a.43.43 0 00.226-.113l7.4-7.4a2.85 2.85 0 00.834-2.029 2.941 2.941 0 00-.837-2.062A2.85 2.85 0 0011.288 0a2.782 2.782 0 00-2.036.86l-7.375 7.4a.407.407 0 00-.136.2l-.95 4.366a.5.5 0 00.136.43.452.452 0 00.318.136zM11.288.905a1.968 1.968 0 011.968 1.968v.023a1.9 1.9 0 01-.566 1.38L9.909 1.471a1.945 1.945 0 011.379-.566zM9.275 2.127l2.782 2.782-6.447 6.424-2.782-2.76zM2.466 9.479l2.239 2.239-2.873.633z"
              data-name="Path 3248"
            ></path>
            <path
              d="M15.609 15.835H.452a.452.452 0 000 .9h15.157a.452.452 0 000-.9z"
              data-name="Path 3249"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EditOrg;
