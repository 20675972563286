import React from "react";

function NewConvayLogo(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210.617"
      height="50.365"
      data-name="Component 48 – 217"
      viewBox="0 0 210.617 50.365"
      {...props}
    >
      <g fill="#365d7e" data-name="Group 1">
        <path
          d="M1211.311 265.962c-.176.777-.334 1.471-.489 2.165l-1.6 7.148c-.108.481-.244.958-.325 1.444-.055.333-.23.4-.517.354a29.941 29.941 0 01-5.645-1.354c-1.256-.442-2.471-.977-3.679-1.53-.235-.108-.306-.22-.25-.508.667-3.36 1.312-6.724 1.961-10.088.037-.191.054-.387.1-.575.077-.324 0-.409-.335-.293-1.315.453-2.642.876-3.958 1.326-2.461.843-4.892 1.77-7.313 2.725a.385.385 0 01-.5-.1 33.532 33.532 0 01-5.455-7.6c-.125-.237-.02-.271.167-.347a183.367 183.367 0 0118.559-6.633c2.084-.605 4.191-1.124 6.288-1.678a.447.447 0 01.5.175c1.22 1.3 2.469 2.58 3.68 3.891a61.922 61.922 0 014.6 5.718 43.9 43.9 0 015.145 9.4c.358.892.632 1.817.952 2.725a.289.289 0 01-.094.369 18.236 18.236 0 01-5.477 3.343c-.235.094-.259-.062-.326-.2-.661-1.359-1.322-2.718-2.047-4.045a57.646 57.646 0 00-3.684-5.57 3.349 3.349 0 00-.258-.262z"
          data-name="Path 1"
          transform="translate(-1180.031 -226.719)"
        ></path>
        <path
          d="M1191.629 241.457c-.734-.417-1.419-.811-2.109-1.2q-3.4-1.892-6.881-3.623c-.291-.143-.331-.258-.169-.552a17.948 17.948 0 013.733-4.886 23.8 23.8 0 011.868-1.48.44.44 0 01.5-.032 91.642 91.642 0 018.581 4.578 2.327 2.327 0 00.236.138c.149.067.245.069.223-.152a29.31 29.31 0 00-.749-5.084c-.178-.652-.416-1.288-.645-1.925-.094-.26-.078-.386.241-.4.963-.03 1.926-.119 2.89-.072a27.65 27.65 0 014.178.519.568.568 0 01.408.313 19.46 19.46 0 012.552 6.881c.17 1.063.284 2.136.35 3.215.118 1.878.064 3.755.056 5.631 0 .95-.1 1.9-.136 2.847-.008.3-.2.323-.4.377-1.834.483-3.681.927-5.5 1.456q-5.078 1.477-10.055 3.271a213.912 213.912 0 00-8.947 3.49c-.246.1-.387.111-.482-.2a27.306 27.306 0 01-1.185-5.518 28.048 28.048 0 01-.149-2.883.444.444 0 01.328-.488c3.539-1.417 7.1-2.762 10.721-3.963a1.132 1.132 0 00.542-.258z"
          data-name="Path 2"
          transform="translate(-1180.037 -226.763)"
        ></path>
        <path
          d="M1225.242 270.369c-.2-.585-.394-1.12-.561-1.664a43.505 43.505 0 00-7.48-13.823 66.788 66.788 0 00-6.221-6.918.924.924 0 01-.311-.754c.055-1.485.121-2.971.127-4.456.016-4.19-.356-8.311-2.288-12.136a32.998 32.998 0 00-1.253-2.224c.06-.02.1-.049.124-.04a38.063 38.063 0 015.644 2.4 8.635 8.635 0 013.878 4.692 33.275 33.275 0 011.377 5.046c.067.322.191.4.523.393a25.118 25.118 0 014.607.139.831.831 0 01.652.358 32.777 32.777 0 013.979 8.08c.334 1.082.595 2.187.887 3.282.069.261.032.385-.294.279a19.044 19.044 0 00-4.714-.863c-.2-.013-.471-.113-.58.074-.1.166.123.349.22.51a45.388 45.388 0 013.988 8c.329.92.64 1.846.961 2.77a.841.841 0 01.009.538 19.654 19.654 0 01-3.122 6.182 1.633 1.633 0 01-.152.135z"
          data-name="Path 3"
          transform="translate(-1179.987 -226.76)"
        ></path>
      </g>
      <g data-name="Group 3" transform="translate(68.631 5.734)">
        <g data-name="Group 2">
          <path
            fill="#365d7e"
            d="M876.064 322.483c-.211 0-.421 0-.622.01-.161 0-.312.012-.469.017-.06 0-.121 0-.179.007a12.05 12.05 0 00-6.574 1.848 8.752 8.752 0 00-2.2 2.3 10.681 10.681 0 00-1.447 3.2 33.347 33.347 0 00-1 9.112v.475a5 5 0 00.01.392 31.957 31.957 0 00.986 8.72 9.508 9.508 0 003.725 5.5q2.711 1.877 7.77 1.881c.209 0 8.454 0 8.673-.009v-3.279l-8.737.001a9.83 9.83 0 01-5.323-1.4 6.727 6.727 0 01-2.648-4.154 28.606 28.606 0 01-.639-6.418h.027a48.3 48.3 0 01-.029-1.3v-.236a37.962 37.962 0 01.684-7.76 8.843 8.843 0 011-2.547 6.179 6.179 0 011.6-1.667 9.841 9.841 0 015.323-1.4h1.196l7.441-.01h.1v-3.277c-.214-.006-8.459-.006-8.668-.006z"
            data-name="Path 4"
            transform="translate(-863.573 -322.483)"
          ></path>
          <path
            fill="#365d7e"
            d="M891.165 354.586a7.029 7.029 0 01-3.194-3.934 21.444 21.444 0 01-.953-7.055 21.673 21.673 0 011-7.271 7.239 7.239 0 013.242-4 12.106 12.106 0 015.959-1.264 11.914 11.914 0 015.984 1.288 7.383 7.383 0 013.217 4.006 26.657 26.657 0 01.048 14.3 7.067 7.067 0 01-3.171 3.934 15.192 15.192 0 01-12.134 0zm10.108-2.717a4.7 4.7 0 001.931-2.813 21.054 21.054 0 00.548-5.459 21.963 21.963 0 00-.573-5.721 4.708 4.708 0 00-1.955-2.861 7.542 7.542 0 00-3.957-.858 7.676 7.676 0 00-3.981.858 4.764 4.764 0 00-2 2.861 27.464 27.464 0 00-.048 11.156 4.668 4.668 0 001.955 2.838 7.724 7.724 0 004.076.881 7.488 7.488 0 004.004-.882z"
            data-name="Path 5"
            transform="translate(-862.106 -321.987)"
          ></path>
          <path
            fill="#365d7e"
            d="M924.32 334.071v.676a4.785 4.785 0 00-2.456-.676z"
            data-name="Path 6"
            transform="translate(-859.887 -321.75)"
          ></path>
          <path
            fill="#365d7e"
            d="M930.558 342.784v12.59h-3.534v-12.49a25.9 25.9 0 00-.4-5.2 4.453 4.453 0 00-1.452-2.62 2.31 2.31 0 00-.155-.11 4.785 4.785 0 00-2.456-.676.783.783 0 00-.146-.009l-.219.009h-7.039v21.1h-3.578v-24.317h10.837v.01c.118-.01.247-.01.375-.01.2 0 .393 0 .575.018a7.641 7.641 0 014.144 1.123 6.229 6.229 0 012.356 3.642 27.468 27.468 0 01.692 6.94z"
            data-name="Path 7"
            transform="translate(-860.565 -321.985)"
          ></path>
          <path
            fill="#365d7e"
            d="M921.887 334.072h-.365l.219-.008a.793.793 0 01.146.008z"
            data-name="Path 8"
            transform="translate(-859.905 -321.75)"
          ></path>
          <path
            fill="#365d7e"
            d="M973.389 345.779v-7.048q0-4-1.835-5.834c-1.178-1.178-3.031-1.781-5.56-1.826v-.01h-9.587v3.139h9.633c2.51.054 3.771 1.57 3.771 4.529v2.054l-7.487.712q-3.808.383-5.552 2.054a6.775 6.775 0 00-1.743 5.1 6.271 6.271 0 001.717 4.815 6.483 6.483 0 005.012 1.863c.082 0 .173-.01.256-.01v.01h11.376v-3h-.018v-6.55zm-3.579 6.55h-7.487a1.449 1.449 0 01-.192-.009c-2.282-.074-3.432-1.017-3.432-3.857a4.387 4.387 0 01.959-3.059 4.62 4.62 0 013.095-1.232l7.058-.667z"
            data-name="Path 9"
            transform="translate(-857.84 -321.984)"
          ></path>
          <path
            fill="#365d7e"
            d="M961.682 350.974a1.447 1.447 0 00.192.009h-.192z"
            data-name="Path 10"
            transform="translate(-857.362 -320.679)"
          ></path>
          <path
            fill="#365d7e"
            d="M945.411 347.215c-.848 2.545-1.182 3.736-1.6 4.836h-.252c-.453-1.276-1.278-3.141-2.435-6.447l-5.253-14.1H932l9.039 23.812h5.378l7.879-23.812h-3.87c-2.607 8.413-4.205 13.281-5.015 15.711z"
            data-name="Path 11"
            transform="translate(-859.295 -321.957)"
          ></path>
          <path
            fill="#365d7e"
            d="M998.522 331.506l-9.7 26.349-.557 1.479a12.326 12.326 0 01-3.233 5.186 6.422 6.422 0 01-2.73 1.333 7.6 7.6 0 01-1.8.2 13.428 13.428 0 01-2.958-.329V363.1a11.623 11.623 0 002.429.237c2.245 0 3.843-1.269 4.812-3.8l.666-1.68.986-2.511h-2.156l-8.143-23.838h3.57l7.34 20.733h.192l7.7-20.733z"
            data-name="Path 12"
            transform="translate(-856.535 -322.049)"
          ></path>
          <path
            fill="#365d7e"
            d="M984.909 356.187l-.666 1.68.657-1.68z"
            data-name="Path 13"
            transform="translate(-855.988 -320.382)"
          ></path>
          <path
            fill="#587381"
            d="M0 3.205L0 0"
            data-name="Line 1"
            transform="translate(98.62 9.118)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default NewConvayLogo;
