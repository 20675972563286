import * as React from "react";

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path data-name="Path 17" d="M24 24H0V0h24z" fill="none" opacity={0.87} />
      <path
        data-name="Path 18"
        d="M15.88 9.29L12 13.17 8.12 9.29a1 1 0 00-1.41 1.41l4.59 4.59a1 1 0 001.41 0l4.59-4.59a1 1 0 000-1.41 1.017 1.017 0 00-1.42 0z"
        fill="#0a2463"
      />
    </svg>
  );
}

export default SvgArrowDown;
