import React from "react";

function Check() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 256 256"
    >
      <path
        fill="#2a5b77"
        strokeMiterlimit="10"
        d="M26.98 5.99a1 1 0 00-.687.303L11 21.586l-6.293-6.293a1 1 0 10-1.414 1.414l7 7a1 1 0 001.414 0l16-16a1 1 0 00-.727-1.717z"
        fontFamily="none"
        fontSize="none"
        fontWeight="none"
        textAnchor="none"
        transform="scale(8.53333)"
      ></path>
    </svg>
  );
}

export default Check;
