import React from "react";
function sendInvitation(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="22.285"
      // height="23.147"
      viewBox="0 0 22.285 23.147"
      {...props}
    >
      <g data-name="Component 315 – 12" transform="translate(.6 1.339)">
        <g data-name="Group 4272" transform="translate(0 .295)">
          <path
            fill="none"
            stroke="#636d82"
            strokeLinecap="round"
            strokeWidth="1.2"
            d="M20.913 10.457v5.728a4.728 4.728 0 01-4.729 4.729H4.729A4.729 4.729 0 010 16.185V4.729A4.729 4.729 0 014.729 0h5.728"
            data-name="Rectangle 2582"
          ></path>
        </g>
        <g data-name="Group 4273" transform="translate(5.597)">
          <g data-name="Group 2949" transform="translate(0 5.114)">
            <path
              fill="none"
              stroke="#636d82"
              strokeLinecap="round"
              strokeWidth="1.2"
              d="M8.844 0H3.957S-.002-.153-.002 4.729"
              data-name="Path 735"
            ></path>
          </g>
          <path
            fill="none"
            stroke="#636d82"
            strokeLinecap="round"
            strokeWidth="1.2"
            d="M15.187 5.119l-5.741 5.119V0z"
            data-name="Path 736"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default sendInvitation;
