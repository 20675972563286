import React from "react";

function UpDownArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.893"
            height="16.995"
            viewBox="0 0 9.893 16.995"
        >
            <g transform="translate(.001)">
                <g data-name="Group 12415" transform="translate(-.001)">
                    <path
                        fill="#b5b5c3"
                        d="M107.755 7.489h8.945c.541 0 .5-.9.441-1.344a1.215 1.215 0 00-.183-.456L112.876.472c-.358-.63-.938-.63-1.3 0L107.5 5.689a1.215 1.215 0 00-.183.456c-.059.448-.103 1.344.438 1.344z"
                        data-name="Path 3289"
                        transform="translate(-107.283)"
                    ></path>
                    <path
                        fill="#2a5b77"
                        d="M107.5 302.045l4.083 5.216c.358.63.938.63 1.3 0l4.084-5.217a1.216 1.216 0 00.183-.456c.056-.448.1-1.344-.441-1.344h-8.948c-.541 0-.5.9-.44 1.345a1.219 1.219 0 00.179.456z"
                        data-name="Path 3290"
                        transform="translate(-107.283 -290.739)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default UpDownArrow;

