import * as React from "react"

const CheckIcon = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} {...props}>
    <g data-name="Group 10997">
      <circle
        data-name="Ellipse 7183"
        cx={8.5}
        cy={8.5}
        fill="#5ed2c2"
        r={8.5}
      />
      <path
        data-name="Path 407"
        d="m3.74 8.134 3.447 3.4 6.073-6.075"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
)

export default CheckIcon
