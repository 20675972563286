import { createSlice } from "@reduxjs/toolkit";

interface SchedulerState {
  scheduleCreateResponse: number;
}

const initialState: SchedulerState = {
  scheduleCreateResponse: 0,
};

export const schedulerSlice = createSlice({
  name: "scheduler",
  initialState,
  reducers: {
    setScheduleCreateResponse: (state: SchedulerState) => {
      state.scheduleCreateResponse = state.scheduleCreateResponse + 1;
    },
  },
});

export const { setScheduleCreateResponse } = schedulerSlice.actions;

export default schedulerSlice.reducer;
