import * as React from "react";
import { SVGProps } from "react";

const YellowStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20200907-tshirt-05.jpg"
    height="20200907-tshirt-05.jpg"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter
        x={-5}
        y={-4}
        width={84}
        height={83}
        filterUnits="userSpaceOnUse"
        id="yellow-star_svg__b"
      >
        <feOffset dx={5} dy={5} in="SourceAlpha" result="shadowOffsetInner" />
        <feGaussianBlur
          stdDeviation={5}
          in="shadowOffsetInner"
          result="shadowGaussian"
        />
        <feComposite
          in2="shadowGaussian"
          operator="atop"
          in="SourceAlpha"
          result="shadowComposite"
        />
        <feColorMatrix
          values="0 0 0 0 0.0392156862745098 0 0 0 0 0.141176470588235 0 0 0 0 0.388235294117647 0 0 0 1 0"
          in="shadowComposite"
        />
      </filter>
      <path
        d="M41.846 23v-.863L35.984 2.99a3.982 3.982 0 0 0-7.615 0L22.471 23H4.729c-.008-.863-.016-.863-.025-.863a3.945 3.945 0 0 0-2.286 7.16l14.503 10.28-5.694 18.245a3.977 3.977 0 0 0 6.217 4.311l14.713-11.076L46.87 62.173a3.977 3.977 0 0 0 6.22-4.312l-5.702-18.236L61.9 29.339a3.943 3.943 0 0 0-2.278-7.16c-.011 0-.022 0-.033.821H41.846Z"
        fill="#f19901"
        transform="translate(0 1)"
        id="yellow-star_svg__a"
      />
    </defs>
    <g transform="translate(5 4)">
      <use xlinkHref="#yellow-star_svg__a" filter="url(#yellow-star_svg__b)" />
      <use xlinkHref="#yellow-star_svg__a" />
    </g>
  </svg>
);

export default YellowStar;
