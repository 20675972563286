import React from "react";

function Speaker() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g data-name="Group 12815" transform="translate(-896 -653)">
        <g data-name="Group 11734" transform="translate(0 -27)">
          <g data-name="Group 11733" transform="translate(24 380)">
            <g
              fill="none"
              stroke="#a8acb6"
              strokeWidth="1.1"
              data-name="Ellipse 7661"
              transform="translate(872 300)"
            >
              <circle cx="6" cy="6" r="6" stroke="none"></circle>
              <circle cx="6" cy="6" r="5.45"></circle>
            </g>
          </g>
          <circle
            cx="2"
            cy="2"
            r="2"
            fill="#a8acb6"
            data-name="Ellipse 7662"
            transform="translate(900 684)"
          ></circle>
        </g>
      </g>
    </svg>
  );
}

export default Speaker;
