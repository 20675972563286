import React from "react";

function AudioRectangle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="45"
      viewBox="0 0 111.499 59.905"
    >
      <g data-name="Group 13254" transform="translate(-840.176 -941.874)">
        <g data-name="Group 4108" transform="translate(158.871 508.945)">
          <rect
            width="111.248"
            height="59.654"
            fill="#a8aebc"
            stroke="#a8aebc"
            strokeWidth="0.25"
            data-name="Rectangle 14"
            rx="8"
            transform="translate(681.43 433.054)"
          ></rect>
        </g>
        <rect
          width="111.248"
          height="59.654"
          fill="#eaecf0"
          stroke="#a8aebc"
          strokeWidth="0.25"
          data-name="Rectangle 14"
          rx="8"
          transform="translate(840.301 942)"
        ></rect>
        <g
          fill="#6f7687"
          data-name="Group 11413"
          transform="translate(-898.125 65.785)"
        >
          <rect
            width="8.609"
            height="11.966"
            data-name="Rectangle 167"
            rx="2.154"
            transform="translate(1789.746 896.697)"
          ></rect>
          <path
            d="M1806.173 901.907a.984.984 0 00-.989.98v2.3a3.458 3.458 0 01-3.681 3.172h-4.903a3.459 3.459 0 01-3.682-3.172v-2.3a.988.988 0 00-1.976 0v2.3a5.421 5.421 0 005.658 5.134h1.389v2.146a.989.989 0 001.977 0v-2.146h1.541a5.421 5.421 0 005.658-5.134v-2.3a.984.984 0 00-.992-.98z"
            data-name="Path 65"
            transform="translate(-5 1.935)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default AudioRectangle;
