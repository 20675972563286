import { Link, Router, useHistory, useLocation } from "react-router-dom";
import useJwtToken from "../../../config/auth/useJwtToken";
import {environment} from "../../../config/environments/environment";
import ConvayLogo from "../../../../assets/icons/react-svg/ConvayLogo";
import {
  HomeIcon,
  MeetingIcon,
  AdminSetting,
  MenuCollapseIcon,
  HamburgerIcon,
  Cross,
  SubMenuDownArrow,
  FileIcon
} from "../../../../assets/icons";
import MeetingManagementIcon from "../../../../assets/icons/react-svg/MeetingManagementIcon";
import UserManagementIcon from "../../../../assets/icons/react-svg/UserManagementIcon";
import OrgManagement from "../../../../assets/icons/react-svg/OrgManagementIcon";
import SvgRoleIcon from "../../../../assets/icons/react-svg/RoleIcon";
import React, { useEffect, useMemo, useState } from "react";
import { getOrganizationInfo } from "../../../common/api-services/adminPanelApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import ChatIcon from "../../../../assets/icons/react-svg/ChatIcon";
import axios from "axios";
import { redirectToMatrix } from "../Matrix";
import { setMatrixChatVisibility, setMatrixState } from "../../../redux/slices/showMatrixChatSlice";
import Cookies from "js-cookie";
import { checkFeatureForUser } from "../../../common/utility/checkFeatureForUser";
import { USER_FEATURES } from "../../../common/utility/constants";
export default function sidebar() {
  const [isCollapse, setIsCollapse] = useState(false);
  const [hasSubMenu, setHasSubMenu] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const dispatch = useDispatch();
  const showMatrix = useSelector((state: RootState) => state.matrix.showMatrix);
  const authUser = useJwtToken();
  const [organizationLogo, setOrganizationLogo] = useState<any>();
  const [organizationName, setOrganizationName] = useState<any>();
  const [firstOrgWord, setFirstOrgWord] = useState<any>();
  const [secondOrgWord, setSecondOrgWord] = useState<any>();
  const orgInfo = useSelector((state: RootState) => state.organization.orgInfo);
  const [chatClass, setChatClass] = useState(false);
  const loc = useLocation();
  const authToken: string | undefined = Cookies.get("auth_token");
  const history = useHistory();

  useEffect(() => {
    checkChatAvailability()
  }, [])
  const checkChatAvailability = async () => {

    const isAvailable = await checkFeatureForUser(USER_FEATURES.CHAT);
    if (isAvailable) {
      dispatch(setMatrixChatVisibility(true))
    }
  }

  function setIsCollapseChange() {
    setIsCollapse(!isCollapse);
  }

  function setHasSubMenuChange() {
    setHasSubMenu(!hasSubMenu);
  }

  function setMobileMenuChange() {
    setMobileMenu(!mobileMenu);
  }

  const shouldDisplayMatrixChat = useSelector(
    (state: RootState) => state.matrix.isChatVisible
  );

  function setActiveClass(val: string) {
    if (!chatClass) {
      if (val === "home") {
        if (!showMatrix) {
          return "cnv-active";
        } else if (showMatrix) {
          return "";
        }
      } else {
        if (val === loc.pathname) {
          return "cnv-active";
        } else {
          return "";
        }
      }
    }
    return "";
  }

  useMemo(() => showMatrix && setChatClass(true), [showMatrix]);

  useEffect(() => {
    /*
     * get all information of an organization
     */
    getOrganizationInfo(authUser?.user.organization_id).then((res: any) => {
      setOrganizationName(res.data.org_name);
      let str = res.data.org_name;
      str = str.replace(/(^\s*)|(\s*$)/gi, "");
      str = str.replace(/[ ]{2,}/gi, " ");
      str = str.replace(/\n /, "\n");
      let length = str.split(" ").length;
      let firstWordOrg = res.data.org_name.split(" ").slice(0, 1).join("");
      if (firstWordOrg) {
        setFirstOrgWord(firstWordOrg[0].toUpperCase());
      }
      if (length > 1) {
        let secondWordOrg = res.data.org_name.split(" ").slice(1, 2).join("");
        setSecondOrgWord(secondWordOrg[0].toUpperCase());
      }
      setOrganizationLogo(res.data.org_logo);
    });
  }, [orgInfo]);


    return (
        <>
            <span
                style={
                    mobileMenu
                        ? {display: "none"}
                        : {display: "block"}
                }

                onClick={setMobileMenuChange} className="hamburgerIcon"><HamburgerIcon/></span>
            <div
                className={isCollapse ? "cnv-left-sidebar cnv-left-sidebar-collapse" : "cnv-left-sidebar"}
            >

                <span onClick={setMobileMenuChange} className="clossMobileMenu"><Cross/></span>

                <span
                    onClick={setIsCollapseChange}
                    className="menu-collapse-arrow"><MenuCollapseIcon/></span>

                <div className={mobileMenu ? "overflow_hidden cnv-hasMobileMenu" : "overflow_hidden"}>

                    <div className="cnv-org-name">

                        <Link to="/home">

                            {organizationLogo ? (
                                <img
                                    src={environment.fileServiceApi + "file/" + organizationLogo}
                                />
                            ) : organizationName ? (
                                <>
                                <span className="cnv-org-name-icon">
                                    {!secondOrgWord ? firstOrgWord : firstOrgWord + secondOrgWord}
                                </span>
                                    <span className="company-name">{organizationName}</span>
                                </>
                            ) : null}
                        </Link>
                    </div>

          <div className="cnv-left-sidebar-container">
            <ul>
              <li
                onClick={() => setChatClass(false)}
                className={setActiveClass("/home")}
                style={
                  authUser?.permission.view_dashboard
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Link to="/home" className="cnv-link home-link" onClick={() => dispatch(setMatrixState(false))}>
                  <HomeIcon />
                  <span>Home</span>{" "}
                </Link>
              </li>
              <li
                onClick={() => setChatClass(false)}
                className={setActiveClass("/meeting-management")}
                style={
                  authUser?.permission.view_meeting
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <Link
                  to="/meeting-management"
                  className="cnv-link"
                  data-testid={"meeting-management"}
                  onClick={() => dispatch(setMatrixState(false))}
                >
                  <MeetingIcon />
                  <span>Meeting Management</span>
                </Link>
              </li>
              {shouldDisplayMatrixChat && (
                <li onClick={() => setChatClass(!chatClass)} className={chatClass ? "cnv-active" : ""}>
                  <Link to={history.location.pathname} className="cnv-link" onClick={() => dispatch(setMatrixState(!chatClass))}>
                    <ChatIcon />
                    <span>Chat</span>
                  </Link>
                </li>
              )}
              {/* <li className={setActiveClass("/cloud")}>
                <a href={`https://cloud.convay.com/login?user_crd=${authToken}`} target="_blank" className="cnv-link">
                  <FileIcon />
                  <span>Convay Drive</span>{" "}
                </a>
              </li> */}
              <li
                onClick={() => setChatClass(false)}
                className={
                  hasSubMenu ? "cnv-submenu cnv-hasSubmenu" : "cnv-submenu"
                }
                style={
                  authUser?.permission.view_user
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <span className="submenu-label" onClick={setHasSubMenuChange}>
                  <AdminSetting />
                  <span>Admin Settings</span>{" "}
                  <span className="submenu-arrow">
                    <SubMenuDownArrow />
                  </span>
                </span>
                <ul>
                  <li
                    className={setActiveClass("/user-management")}
                    style={
                      authUser?.permission.view_user
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    onClick={() => setChatClass(false)}
                  >
                    <Link to={`/user-management`} className="cnv-link" onClick={() => dispatch(setMatrixState(false))}>
                      <span>Manage Users</span>{" "}
                    </Link>
                  </li>
                  <li
                    className={setActiveClass("/organization")}
                    style={
                      authUser?.permission.view_organization
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    onClick={() => setChatClass(false)}
                  >
                    <Link to={`/organization`} className="cnv-link" onClick={() => dispatch(setMatrixState(false))}>
                      <span>Manage Organization</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
