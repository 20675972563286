import * as React from "react";

const ConvayHostCopy = (props: any) => (
  <svg
    id="copy-invitation"
    xmlns="http://www.w3.org/2000/svg"
    width={17.631}
    height={19.095}
    viewBox="0 0 17.631 19.095"
    ref={props.svgref}
    {...props}
  >
    <g data-name="Group 11102">
      <g data-name="Group 4930" transform="translate(0 .899)">
        <rect
          data-name="Rectangle 232"
          width={13.671}
          height={15.135}
          rx={3}
          transform="translate(0 3.061)"
          fill="#d3ffb1"
        />
        <path
          data-name="Path 175"
          d="M6.744.001h5.853a4.146 4.146 0 0 1 4.134 4.134v9.429"
          fill="none"
          stroke="#d3ffb1"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.8}
        />
      </g>
    </g>
  </svg>
);

export default ConvayHostCopy;
