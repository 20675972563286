import React from "react";

function StatusChange() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Bahan copy"
            viewBox="0 0 64 64"
        >
            <path
                fill="#231f20"
                d="M55.968 42.032a2.587 2.587 0 00-3.649-.1l-5.405 5.406-2.096-2.096a2.666 2.666 0 00-3.77 3.77l3.727 3.727a2.923 2.923 0 004.113.12l7.18-7.18a2.584 2.584 0 00-.1-3.647zm-1.315 2.234l-7.18 7.18a.793.793 0 01-.572.206 1.082 1.082 0 01-.712-.327l-3.727-3.728a.718.718 0 01-.22-.474c-.049-.195.197-.436.342-.566a.603.603 0 01.82.1l2.803 2.802a1 1 0 001.414 0l6.111-6.111c.448-.441 1.394.388.921.918z"
            ></path>
            <path
                fill="#231f20"
                d="M48.508 34.879a12.545 12.545 0 00-2.259.214 25.723 25.723 0 00-12.6-6.352C45.653 22.393 41.378 4.171 27.604 4c-14.059.21-18.073 18.948-5.572 24.968-10.464 2.229-19.31 11.81-19.101 22.58v3.003A5.455 5.455 0 008.38 60h39.928c16.795-.461 16.997-24.392.199-25.121zM16.49 17.114A11.126 11.126 0 0127.605 6c14.745.61 14.74 21.621 0 22.229a11.126 11.126 0 01-11.114-11.115zM8.381 58a3.453 3.453 0 01-3.45-3.45v-3.002c-.162-12.07 11.443-21.59 23.376-21.323a24.317 24.317 0 0115.64 5.524A12.573 12.573 0 0041.733 58zm40.127 0a10.572 10.572 0 01-10.56-10.56c.58-14.01 20.543-14.007 21.12 0A10.572 10.572 0 0148.508 58z"
            ></path>
        </svg>
    );
}

export default StatusChange;
